export class DeepLinkRoutes  {
   static routes = [
    {
        "pathName": "submit-paperwork",
        "orginalPath": "loads/paperwork",
        "pathVal1": "stakeHolderId",
        "pathVal2": "tripId",
    }
   ];
}
