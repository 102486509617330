<div *ngIf="!currentTrip" class="no-current-jobs">
    No current trips found.
</div>
<div *ngIf="currentTrip">

    <app-badge-status-card *ngIf="checkSafetyAlert() === true" [messageText]="badgeMessage" [iconName]="badgeIcon"
        [textColor]="badgeTextColor"></app-badge-status-card>

    <div *ngIf="currentTrip && currentTrip.trailerReservationNotice">
        <job-details [loadStatusSummary]="currentTrip.trailerReservationNotice" [origin]="origin"
            [destination]="destination"></job-details>
    </div>

    <ion-card>
        <ion-card-header class="main-card ion-padding-bottom">
                <ion-row>
                   <ion-col size="12">
                        <ion-row>
                            <ion-col class="ion-no-padding" size="6">
                                <ion-item lines="none">
                                    <ion-label position="stacked" >
                                        Trip ID
                                    </ion-label>
                                    <ion-text class="edge-value data-value">{{currentTrip?.id}}</ion-text>
                                </ion-item>
                            </ion-col>
                           
                            <ion-col class="ion-no-padding" size="6">
                                <ion-item lines="none">
                                    <ion-label position="stacked" >
                                        Shipper Order
                                    </ion-label>
                                    <ion-text class="edge-value data-value">{{currentTrip?.loadNumber}}</ion-text>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-col>

                    <ion-col size="12">
                        <ion-row>
                            <ion-col size="7">
                                <ion-button expand="block" class="main-card-button" (click)="startPreTripInspection()">
                                    <fa-icon [icon]="['fas', 'pencil-alt']" class="main-card-button-icon"></fa-icon>
                                    <ion-label class="button-label ion-text-capitalize">
                                        Pre-Trip Inspection
                                    </ion-label>
                                </ion-button>
                            </ion-col>
                            <ion-col size="5">
                                <ion-button expand="block" class="main-card-button" (click)="callFleetManager()">
                                    <fa-icon [icon]="['fas', 'phone']" class="main-card-button-icon"></fa-icon>
                                    <ion-label class="button-label ion-text-capitalize">
                                        Manager
                                    </ion-label>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-col>


                </ion-row>


        </ion-card-header>
        <ion-card-content *ngIf="!(this.platform.is('desktop') || this.platform.is('mobileweb'))">
            <div class="ion-text-center ion-margin-top">
                <ion-button expand="block" size="default" (click)='showPaperworkDrawer()'>
                  Paperwork
                </ion-button>
                <ion-button fill="clear" class="ion-text-wrap" (click)="onClickUploadProcess()"> How to submit paperwork </ion-button>
            </div>
        </ion-card-content> 
    </ion-card>

    <ion-card>
        <div class="timeline" [ngClass]="getTimelineProgress(tripStep, i)"
            *ngFor="let tripStep of currentTripSteps; let i = index;">
            <div [ngClass]="getPostStopTimeline(tripStep, i)">
                <div [ngClass]="getStopProgress(tripStep, i)" class="container left">
                    <div class="content">
                        <ion-grid>
                            <ion-row class="main-card">
                                <ion-col size="9">
                                    <div class="ion-text-wrap">
                                        <fa-icon class="site-safety-warning-icon pointer" *ngIf="hasSiteSafetyAlert(tripStep.siteSafetyAlertUrl) === true" [icon]="['fas', 'exclamation-triangle']" (click)="viewSafetyAlert(tripStep)"></fa-icon>
                                        <ion-label class="edge-value data-value">{{tripStep.name}}</ion-label>
                                    </div>
                                    <div>
                                        <ion-text class="edge-value data-value">{{ tripStep.location.city }}, {{
                                            tripStep.location.stateCode }} {{ tripStep.location.postalCode }}</ion-text>
                                    </div>
                                </ion-col>
                                <ion-col size="3">
                                    <div class="ion-float-right trailer-container ion-padding-vertical pointer" (click)="viewCurrentJobOnMap(tripStep)">
                                        <fa-icon [icon]="['fas', 'map-marker-alt']" class="trailer-icon"></fa-icon>
                                        <ion-label class="trailer-button-label edge-label"> TRAILER </ion-label>
                                    </div>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                        <ion-grid>

                            <ion-row *ngIf="isArrivalDate(tripStep)">
                                <ion-col size="6">
                                    <ion-label class="edge-label">
                                        Actual Arrival
                                    </ion-label>
                                </ion-col>
                                <ion-col size="6">
                                    <ion-text class="edge-value data-value ">{{ tripStep.arrivalDate | date: 'EEE, MMM d HH:mm' }}
                                    </ion-text>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="!isArrivalDate(tripStep) && isTargetDate(tripStep)">
                                <ion-col size="6">
                                    <ion-label class="edge-label">
                                        Target Arrival
                                    </ion-label>
                                </ion-col>
                                <ion-col size="6">
                                    <ion-text class="edge-value data-value">{{ tripStep.targetDate | date: 'EEE, MMM d HH:mm' }}
                                    </ion-text>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="isDepartureDate(tripStep)">
                                <ion-col size="6">
                                    <ion-label class="edge-label">
                                        Actual Departure
                                    </ion-label>

                                </ion-col>
                                <ion-col size="6">
                                    <ion-text class="edge-value data-value">{{ tripStep.departureDate | date: 'EEE, MMM d HH:mm' }}
                                    </ion-text>
                                </ion-col>
                            </ion-row>
                            <ion-row
                                *ngIf="isArrivalDate(tripStep) && !isDepartureDate(tripStep) && isTargetDate(tripStep)">
                                <ion-col size="6">
                                    <ion-label class="edge-label">
                                        Target Departure
                                    </ion-label>

                                </ion-col>
                                <ion-col size="6">
                                    <ion-text class="edge-value data-value">{{ tripStep.departureDate | date: 'EEE, MMM d HH:mm' }}
                                    </ion-text>
                                </ion-col>
                            </ion-row>

                            <ion-row>
                                <ion-col size="6">
                                    <ion-label class="edge-label">
                                        Trailer#
                                    </ion-label>

                                </ion-col>
                                <ion-col size="6">
                                    <ion-label class="edge-value data-value">
                                        {{tripStep.trailerNumber}}
                                    </ion-label>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngIf="tripStep.pickUpNumber">
                                <ion-col size="6">
                                    <ion-label class="edge-label">
                                        Pickup#
                                    </ion-label>
                                </ion-col>
                                <ion-col size="6">
                                    <ion-label class="edge-value data-value">
                                        {{tripStep.pickUpNumber}}
                                    </ion-label>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <div class="pointer">
                                        <a (click)="locationFeedback(tripStep)"> Location Feedback </a>
                                    </div>
                                </ion-col>
                            </ion-row>
                         
                            <div *ngIf="tripStep.showMe">
                                <ion-row *ngIf="tripStep.pickUpInstructions">
                                    <ion-col size="6">
                                        <ion-label class="edge-label">
                                            Pickup Instructions
                                        </ion-label>
                                    </ion-col>
                                    <ion-col size="6">
                                        <ion-label class="edge-value data-value">
                                            {{tripStep.pickUpInstructions }}
                                        </ion-label>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngIf="tripStep.siteInstructions">
                                    <ion-col size="6">
                                        <ion-label class="edge-label">
                                            Site Instructions
                                        </ion-label>
                                    </ion-col>
                                    <ion-col size="6">
                                        <ion-label class="edge-value data-value">
                                            {{tripStep.siteInstructions }}
                                        </ion-label>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <div class="cta werner-button primary" (click)='viewCurrentJobOnMap(tripStep)'>
                                        SHOW LOCATION ON MAP
                                    </div>
                                </ion-row>

                            </div>
                        </ion-grid>
                    </div>
                </div>
            </div>
        </div>
    </ion-card>
    <bottom-drawer *ngIf="showBottomDrawer" [title]="'Start Paperwork'" [bottomDrawerShouldHide]=true [backdrop]=true
        [middleOffset]=300 [actionSheetRows]=actionSheetRows (closedDrawer)="showPaperworkDrawer()">
    </bottom-drawer>
</div>
