import { Inject, Injectable } from "@angular/core";
import {Store} from "@ngrx/store";
import {IUserInfo} from "./user-info.model";
import {DispatchService} from "../shared/services/dispatchers/dispatch.service";
import {StorageConstants} from "../shared/utilities/storage-constants";
import { StorageKeyValue } from "../shared/models/storage-key-value";
import { OktaAuth } from "@okta/okta-auth-js";
import { AlertController, Platform } from "@ionic/angular";
import { environment } from "src/environments/environment";
import OktaSignIn from '@okta/okta-signin-widget';
import { SubSink } from 'subsink';
import * as fromRoot from './../state/root.reducer';
import { Preferences } from '@capacitor/preferences';
import { AdministrationFeatureFlags } from "../Swagger-Gen-V2/model/administrationFeatureFlags";
import { OKTA_AUTH } from "@okta/okta-angular";
import { DeepLinkRoutes } from "../deep-links.routing";
import { Router } from "@angular/router";
import { ToasterUtility } from "../shared/utilities/toaster-utility";

declare let pendo;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  userInfo: IUserInfo;
  widget;
  isPendoEnabled: boolean;
  subscriptions$ = new SubSink();
  featureFlags: AdministrationFeatureFlags;
  config;

  constructor(
    private dispatchService: DispatchService,
    @Inject(OKTA_AUTH) private oktaAuth : OktaAuth,
    private platform: Platform,
    private rootStore: Store<fromRoot.State>,
    public router: Router,
    private toasterUtility: ToasterUtility,
    private alertController: AlertController,
  ) {   
    this.config = new OktaAuth({
      issuer: environment.oidc.issuer,
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
    });
  
    this.widget = new OktaSignIn({
      baseUrl: environment.oidc.issuer.split('/oauth2')[0],
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
      issuer: environment.oidc.issuer,
      useInteractionCodeFlow: true,
      useClassicEngine: true,
      authParams: {
        issuer: environment.oidc.issuer,
        scopes: environment.oidc.scopes,
        display: 'page',
        responseType: environment.oidc.responseType,
      },
      logo: 'assets/Images/Drive_Werner_Logo@3x.png',
      language: 'en',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to Drive Werner Pro',
        },
      },
      authClient: this.config,
      features:{
        showPasswordToggleOnSignInPage : true
      }
    });
  } 

  getWidget() {
    return this.widget;
  }

  async getUserInfo(){
    if(this.userInfo) return this.userInfo;
    
    const userClaim = await this.oktaAuth.token.getUserInfo();
    const userInfo: IUserInfo = {
                                  identityKey : userClaim.identityKey, 
                                  user_name : userClaim.user_name, 
                                  name : userClaim.name, 
                                  preferred_username : userClaim.preferred_username,
                                  given_name: userClaim.given_name
                                };
    this.userInfo = userInfo;

    const identityKeyCheck = await Preferences.get({key: StorageConstants.USER_IDENTITY_KEY});

   // If the logged in user is different, clear storage
   if ((identityKeyCheck.value !== null) && (identityKeyCheck.value !== this.userInfo.identityKey)) {
       this.clearStorage();
   }
   this.featureFlags = await this.dispatchService.getFeatureFlags();

    this.isPendoEnabled = this.featureFlags
      && this.featureFlags.flags
      && this.featureFlags.flags.PendoEnabled !== undefined ? this.featureFlags.flags.PendoEnabled : null;
    if (this.isPendoEnabled) {
      this.initializePendo();
    }

   await Preferences.set({
     key: StorageConstants.USER_IDENTITY_KEY,
     value: this.userInfo.identityKey
   });   

    return userInfo;
  }
  private initializePendo(): void {
    pendo.initialize({
      visitor: {
        id: this.userInfo.preferred_username,
        identityKey: this.userInfo.identityKey,
        givenName: this.userInfo.given_name,
        user_name: this.userInfo.user_name,
        name:this.userInfo.name
      }
    });
  }
  public async setUserProfile() {
   await this.getUserInfo();
   this.dispatchService.homeLoadDispatch(this.userInfo.identityKey);
  }

  async extractStorageKeysNotToBeDeleted() {
    // Keys that should not be deleted even after the user logs off.
    // E.g. Customized cards
    var keysNotToBeDeleted = new Array<StorageKeyValue>();

    let keys = (await Preferences.keys()).keys;
    if (keys && keys.length > 0) {
     keys.forEach(async key => {
        if (key.startsWith(StorageConstants.CARDS_KEY + '_') || key.startsWith(StorageConstants.DEEP_LINK_URL)) {
            let item =  await Preferences.get({ key: key });
            keysNotToBeDeleted.push({
              key : key,
              value : item.value
            });
        }
      });
    }
    return keysNotToBeDeleted;
  }

  async processDeepLinksRouting(url: string) {  
    const routes = DeepLinkRoutes.routes;
    const deepLinkBaseUrl = environment.deepLinks.baseUrl;   
    const pathArray = url.split(deepLinkBaseUrl);
    
    if(pathArray.length >= 1 && pathArray[1]) {
      const pathParams = pathArray[1].split('/');
      const queryParams = pathParams[1].replace("?","").split("&").reduce(function(prev, curr, i, arr) {
        var p = curr.split("=");
        prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
        return prev;
      }, {});
      
      if(await this.validateDeepLinkAuthentication(queryParams)){
        const getRouteInfo = routes.find(route => route.pathName === pathParams[0]);
        this.router.navigate([getRouteInfo.orginalPath], { queryParams: queryParams }); 
        return {path:getRouteInfo.orginalPath, queryParams};
      }else{
        await Preferences.set({ key: StorageConstants.DEEP_LINK_URL, value: url });
        this.logoutConfirmDialogBox();
        this.toasterUtility.showToaster('Error...!',"Deep Link Authentication Failed",'danger');
        return false;
      }
    }else{
      this.toasterUtility.showToaster('Error...!',"It's not a valid deep link.",'danger');
      return false;
    }    
  }

  async logoutConfirmDialogBox() {
    const ionAlert = await this.alertController.create({
      message:
        "You loggedin with different user, to continue this process you have to logout and login with the respective user ?",
      buttons: [
        {
          text: "Logout",
          handler: () => {
            this.signOut();
          },
        },
        {
          text: "No",
          role: "cancel",
        },
      ],
    });
    await ionAlert.present();
    await ionAlert.onDidDismiss();
  }


  async validateDeepLinkAuthentication(queryParams) {
    
    if ('stakeholderId' in queryParams){
      const loggedStakeholderId = await this.dispatchService.getStackholderId();
      if(queryParams['stakeholderId'].toString() === loggedStakeholderId.toString()){
        console.log('Stakeholder Id Matched'); 
        return true;
      }else{
        console.log('Stakeholder Id Not Matched '); 
        return false;
      }     
    }else{
      console.log('User Id not matched');
      return false;
    }

  }

  async addKeysToStorage(keyValues: Array<StorageKeyValue>) {
    // Add the keys back to storage
    if(keyValues.length > 0) {
      for(let i = 0; i < keyValues.length; i++) {
        Preferences.set({ key: keyValues[i].key, value: keyValues[i].value });
      }
    }
  }

  async clearStorage() {
    // Extract keys that are not to be deleted
    const keysNotToBeDeleted = await this.extractStorageKeysNotToBeDeleted();
    
    // Clear the storage
    if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
      localStorage.clear();
    } else {
      localStorage.clear();
      await Preferences.clear();
    }
    
    // Add keys back to the storage
    await this.addKeysToStorage(keysNotToBeDeleted);
  }

  async signOut(): Promise<void> { 
    await this.clearStorage().then(async () => {
      // Sign out method was redirecting to browser, so manually clearing all the tokens and redirecting to log in page
      this.oktaAuth.tokenManager.clear();
      let refreshToken = this.oktaAuth.tokenManager.getTokensSync().refreshToken;
      let accessToken = this.oktaAuth.tokenManager.getTokensSync().accessToken;
      await this.oktaAuth.revokeRefreshToken(refreshToken);
      await this.oktaAuth.revokeAccessToken(accessToken);
      await this.oktaAuth.closeSession();
      window.location.replace('/login');
    });
  }

  onDevice(): boolean {
    return this.platform.is("mobile") && !this.platform.is("mobileweb");
  }

  async getUserIdentitityKey () {
    const identityKey = await Preferences.get({ key: StorageConstants.USER_IDENTITY_KEY });
    return (identityKey && identityKey.value) ? identityKey.value : "";
  }
}
