import { Component, HostListener, NgZone, OnDestroy, OnInit } from "@angular/core";
import { AlertController, Platform, NavController, MenuController, PopoverController, ActionSheetController, PickerController } from "@ionic/angular";
import { environment } from './../environments/environment';
import { AnalyticsService } from "./shared/services/analytics.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { MonitoringService } from "./shared/services/monitoring.service";
import { Title } from "@angular/platform-browser";
import { AppVersionService } from "./shared/services/app-version.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { Store } from "@ngrx/store";
import * as fromRoot from "./state/root.reducer";
import * as rootActions from './state/root.actions';
import { interval } from "rxjs";
import { SplashScreen } from '@capacitor/splash-screen';
import { ToasterUtility } from "src/app/shared/utilities/toaster-utility";
import { ConnectivityService } from "./Swagger-Gen-V2/api/connectivity.service";
import { DispatchService } from "./shared/services/dispatchers/dispatch.service";
import { AuthService } from "./auth/auth.service";
import { LaunchDarklyService } from "./shared/services/launch-darkly.service";
import { LaunchDarklyRoutes } from "./shared/utilities/launch-darkly-constants";
import * as signalR from "@microsoft/signalr"
import { SubSink } from "subsink";
import { register } from 'swiper/element/bundle';
import { LandingPage } from "./modules/landing/landing.page";
import { App, URLOpenListenerEvent } from '@capacitor/app';
register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  providers: [LandingPage]
})
export class AppComponent implements OnInit, OnDestroy {
  isConnectedToWeb = false; // Indicates if the application is connected to the internet
  isEdgeConnectDevice = false;
  internetConnectStatus:boolean = true;
  identityKey : string;
  launchDarklyRoutes = LaunchDarklyRoutes.ROUTES;
  subscriptions$ = new SubSink();
  userImage: string;
  // detect page refresh and check access with launch darkly flags on page reload
  @HostListener('window:load') onLoad() {
    const url = window.location.pathname;
    this.launchDarklyService.validatePageAccess(url);    
  }  
  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    public router: Router,
    private title: Title,
    private appVersionService: AppVersionService,
    private monitoringService: MonitoringService,
    private screenOrientation: ScreenOrientation,
    private navCtrl: NavController,
    private rootStore: Store<fromRoot.State>,
    private menuController:MenuController,
    private popoverController: PopoverController,
    private actionSheetController: ActionSheetController,
    private pickerController : PickerController,
    private connectivity: ConnectivityService,
    private toasterUtility: ToasterUtility,
    private dispatchService : DispatchService,
    private authService : AuthService, 
    private launchDarklyService: LaunchDarklyService,
    public sideMenu: LandingPage, 
    private zone: NgZone
  ) {
    this.isConnectedToWeb = this.hasNetworkConnectivity();
    if (!(this.platform.is("desktop") || this.platform.is("mobileweb"))) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }

    this.isEdgeConnectDevice = environment.isEdgeConnectDevice;

    //Navigation for Android device back button
    this.platform.backButton.subscribeWithPriority(0, () => {
      let isExitApp = true;

      // Identify the very first route of the session and display confirm dialog box to exit app on clicking back button.
      if (
        this.navCtrl["topOutlet"] &&
        this.navCtrl["topOutlet"].stackCtrl &&
        this.navCtrl["topOutlet"].stackCtrl.views &&
        this.navCtrl["topOutlet"].stackCtrl.views.length > 0
      ) {
        isExitApp =
          this.navCtrl["topOutlet"].stackCtrl.views.length === 1 ||
          (this.navCtrl["topOutlet"].stackCtrl.views.length === 2 &&
            this.navCtrl["topOutlet"].stackCtrl.views[1].url === "/home/home");
      }
      if (isExitApp) {
        this.confirmDialogBox();
      } else {
        this.navCtrl.back();
      }
    });

    this.initializeApp();
    this.initializeDeepLinks();    
    // this.startSignalr();
  }

  async validateDeepLinkAuthentication(queryParams) {
    
    if ('stakeholderId' in queryParams){
      const loggedStakeholderId = await this.dispatchService.getStackholderId();
      alert(loggedStakeholderId+"->"+queryParams['stakeholderId']);  
      if(queryParams['stakeholderId'].toString() === loggedStakeholderId.toString()){
        console.log('Stakeholder Id Matched'); 
        return true;
      }else{
        console.log('Stakeholder Id Not Matched '); 
        return false;
      }     
    }else{
      console.log('User Id not matched');
      return false;
    }

  }

  async initializeDeepLinks() { 
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(async () => {
        const url = event.url;
        this.authService.processDeepLinksRouting(url);

			});
		});
	}

  startSignalr(){
    const connection = new signalR.HubConnectionBuilder()
    .withUrl("https://example.com/signalr/chat")
    .build();

    connection.start().then(() => {
      console.log("Connection Started");
    }).catch((err) => {
      console.log(err);
    });

    connection.on("Mentor_Mentee_<idenititykey>", (data) => {
      console.log("========= Message Recieved ==========");
      console.log(data);
    });

  }

  async ngOnInit(): Promise<void> {
    this.identityKey = await this.authService.getUserIdentitityKey();
    if(this.identityKey) {
      console.log('Feom App Component', this.identityKey)
      this.dispatchService.homeLoadDispatch(this.identityKey);
      // Show internet connection / disconnection message
      this.validateInternetConnectivity();
      interval(300000) //setting a time interval of 5 minutes
        .subscribe((val) => {
          this.rootStore.dispatch(new rootActions.GetFeatrueFlagsStatus());
        });
    }
    
    // Validate user had an access while moving from one page to another
    this.subscriptions$.sink = this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
           this.launchDarklyService.validatePageAccess(event.url);
      }
    });

  }

  validateInternetConnectivity(){
    this.subscriptions$.sink = this.connectivity.appIsOnline$.subscribe(online => {
      if (online && !this.internetConnectStatus) {
          this.internetConnectStatus = true;
          this.toasterUtility.showToaster('App Connected...!',"Your internet connection resumed",'success');
      } else if(this.internetConnectStatus && !online) {
          this.internetConnectStatus = false;
          this.toasterUtility.showToaster('App Disconnected...!',"Your internet connection disconnected",'danger');
      }
    })    
  }

  appUpdateCheck() {
    if (this.appVersionService.forcedUpdate()) {
      this.appVersionService.updateApp();
    }
  }

  initializeApp() {
    // Start track passing Tracker Id - Create a regular web account under werner google analytics and pass the ID here
    this.analyticsService.startTrackerWithId(environment.google.analyticsTrackerId);
    this.platform.ready().then(() => {
      this.appUpdateCheck();
      if (this.platform.is("mobile") && !this.platform.is("mobileweb")) {
        SplashScreen.hide();
      }
    });

    this.router.events.subscribe((event) => {
      // observe router and when it start navigation it will track the view
      if (event instanceof NavigationStart) {
        
        if(event.url == "/") 
        {
          setTimeout(() => this.menuController.swipeGesture(true), 500);
        } 
        else 
        {
          setTimeout(() => this.menuController.swipeGesture(false), 500);
        }

        this.monitoringService.startNavigationEvent(event.url);
        let title = this.title.getTitle();
        // get title if it was sent on state
        if (this.router.getCurrentNavigation().extras.state) {
          title = this.router.getCurrentNavigation().extras.state.title;
        }
        // pass url and page title
        this.analyticsService.trackView(event.url, title);
      } else if (event instanceof NavigationEnd) {
        this.monitoringService.endNavigationEvent(event.url);
        this.monitoringService.logPageView();
      }

      // Closing ion-select option pop-ups while navigation  
      this.popoverController.getTop().then(response => {
        if (response) {
          this.popoverController.dismiss();
        }
      });
      this.alertController.getTop().then(response => {
        if (response) {
          this.alertController.dismiss();
        }
      });
      this.actionSheetController.getTop().then(response => {
        if (response) {
          this.actionSheetController.dismiss();
        }
      });
      this.pickerController.getTop().then(response => {
        if(response) {
          this.pickerController.dismiss();
        }
      });
    });
  }

  // This function simply checks if the application has a connection to the web
  hasNetworkConnectivity(): boolean {
    let hasConnection = false;
    hasConnection = navigator.onLine;
    return hasConnection;
  }

  async confirmDialogBox() {
    const alert = await this.alertController.create({
      message:
        "You have selected the back button. This will exit you out of Drive Werner. Do you want to exit the app?",
      buttons: [
        {
          text: "Yes",
          handler: () => {
            navigator["app"].exitApp();
          },
        },
        {
          text: "No",
          role: "cancel",
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  // Check for specific Edge Connect device models
  // Add margin-top if device is found
  edgeConnectStyle() {
    return {
      'margin-top': this.isEdgeConnectDevice ? "44px" : "0px"
    };
  }

  async getCount() {
    this.userImage = await this.dispatchService.getProfileImage();
    this.sideMenu.getCount();
  }
  
  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }  
}
