import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    LoadStatusSummary,
    Trip,
    TripStep
} from '../../../Swagger-Gen';
import { select, Store } from '@ngrx/store';
import * as fromCurrentTrip from './state/trip-load-infos.reducer';
import * as fromPaperWorkReducer from '../../paperwork/state/paperwork.reducer';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import * as paperWorkActions from '../../paperwork/state/paperwork.actions';
import {LoadingUtility} from '../../../shared/utilities/LoadingUtility';
import {environment} from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AnalyticsService} from '../../../shared/services/analytics.service';
import * as tripActions from './state/trip-load-infos.actions';
import * as fromloadStatusActions
    from '../../../shared/components/cards/load-status-card/load-status/state/load-status.reducer';
import { Platform, ToastController } from '@ionic/angular';
import { SubSink } from 'subsink';
import { JobTrip } from 'src/app/Swagger-Gen-V2/model/jobTrip';
import { JobTripStepType } from 'src/app/Swagger-Gen-V2/model/jobTripStepType';
import { WernerDwpContractsLogicJobTrip as trip} from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrip';
import { WernerDwpContractsLogicJobStep } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobStep';
import { WernerDwpContractsLogicJobTrailerReservation as trailerReservation } from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobTrailerReservation';
import { WernerDwpContractsLogicJobEquipment as equipment }from 'src/app/Swagger-Gen-V2/model/wernerDwpContractsLogicJobEquipment';
import { RequestService } from 'src/app/Swagger-Gen-V2/api/request.service';
import { Preferences } from '@capacitor/preferences';
import nativePlugin from 'src/plugins/nativePlugin';
import { AuthService } from 'src/app/auth/auth.service';
import { ProfileInfoService } from '../../profile-v2/service/profileInfo.service';
import { ProfileUtility } from 'src/app/shared/utilities/profile-utility';
import { Profile } from 'src/app/Swagger-Gen-V2';
import { TelemetryUtility } from 'src/app/shared/utilities/telemetry-utilities';
import { TelemetryConstants } from 'src/app/shared/utilities/telemetry-constants';
import { ScannedPaperwork } from 'src/app/Swagger-Gen-V2/model/ScannedPaperwork';
import { BottomDrawerComponent } from 'src/app/shared/components/bottom-drawer/bottom-drawer.component';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';

@Component({
    selector: 'current-job',
    templateUrl: './current-job.component.html',
    styleUrls: ['./current-job.component.scss'],
})
export class CurrentJobComponent implements OnInit, OnDestroy {
    @Input() trip;
    @Output("getCurrentJob") getCurrentJob: EventEmitter<any> = new EventEmitter();
    @Input() isCurrentJobLoading: boolean;
    @Input() closeBottomDrawer: number = 0;
    @ViewChild(BottomDrawerComponent ) bottomDrawer: BottomDrawerComponent; 
    isCardCollapse = 1;
    driverId = '869789';
    componentActive = true;
    currentTrip: trip = null;
    convertedTrip: JobTrip;
    trailerReservation: trailerReservation;
    currentTripObservable: Observable<any>;
    errorMessage$: Observable<string>;
    isIos = false;
    loadNotesCardTitle = 'Critical Load Notes PU#';
    currentTripSteps: Array<WernerDwpContractsLogicJobStep> = new Array<WernerDwpContractsLogicJobStep>();
    currentTripLoadNotes = '';
    isEdgeConnectDevice: boolean = false;
    loadStatusCardTitle = '';
    showStatusCardActionBar = true;
    statusDefinition = '';
    scheduledArrival = new Date();
    statusSummary: LoadStatusSummary = {};
    origin = {
        city: '',
        state: '',
        isTrailerReservation: false
    };
    destination = {
        city: '',
        state: ''
    };
    tripStepTitle = 'ORIGIN';
    locationCards = [];
    // statusSummary = { tripId: 'D2565764PR', truckNumber: '49148', trailerNumber: '71001'};

    showStartPaperworkModal = false;

    badgeMessage = 'A stop on this trip has a safety alert. Tap the alert icon(s) below to review it.';
    badgeIcon = 'warning-outline';
    badgeTextColor = '#FFFFFF';

    SELECTED_STOP_KEY = 'selected_stop';
    CLICKED_TRIP_KEY = 'clicked_trip';
    SELECTED_LOAD_STATUS_FOR_MAP = 'selected_load_status_for_map';
    SELECTED_TRIP_STEPS = 'selected_trip_steps';
    isExpanded = true;

    actionSheetRows = [{
        title: 'Generate Job Envelope and Scan',
        handler: () => {
            this.generateJobEnvelopeAndScan();
        }
        // route: "/loads/paperwork"
    },
    {
        title: 'Scan Paperwork Only',
        handler: () => {
            this.scanPaperWork();
        }
    },
    {
        title: 'View Existing Paperwork',
        handler: () => {
            this.viewExistingPaperWork();
        },
    }
    ];

    showBottomDrawer = false;
    LOCAL_CURRENT_TRIP_KEY = 'local-current-trip';
    LOCAL_CURRENT_STATUS_SUMMARY_KEY = 'local-current-status-summary';
    LOCAL_CURRENT_TRIP_DRIVER_KEY = 'local-current-trip-driver-id';
    LOCAL_SUMMARY_STATUS_DRIVER_KEY = 'local-summary-status-driver-id';
    private subscriptions$ = new SubSink();
    trailerListDetails: equipment[];
    profile : Profile;
    employeeNumber : string;
    paperworkSubmissionVideoUrl : string;


    // Instantiates the component
    constructor(
        private store: Store<fromCurrentTrip.State>,
        private statusStore: Store<fromloadStatusActions.State>,
        private loadingUtility: LoadingUtility,
        private paperWorkStore: Store<fromPaperWorkReducer.State>,
        private router: Router,
        private inAppBrowser: InAppBrowser,
        public platform: Platform,
        private analytics: AnalyticsService,
        private telemetryUtility: TelemetryUtility,
        private requestService: RequestService,
        private toastController: ToastController,
        private authService : AuthService,
        private profileInfoService : ProfileInfoService,
        private profileUtility : ProfileUtility,
        private activatedRoute: ActivatedRoute,
        private dispatchService: DispatchService
    ) {}

    // Handler called when the component is initialized
    async ngOnInit() {
        
        this.isEdgeConnectDevice = environment.isEdgeConnectDevice;
        this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.getTrailers))
        .subscribe(async (TrailersListResponse: Array<equipment>) => {
            if (TrailersListResponse)
                this.trailerListDetails = TrailersListResponse;
        });

        let selectedTab = this.activatedRoute.snapshot.queryParamMap.get("page");
        this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.isLoading), 
        takeWhile(() => this.componentActive)).subscribe((isLoading: boolean) => {
            // make this loader to show only for current tab
            this.loadingUtility.hideLoader();
        });


                
        this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.getTrailers))
        .subscribe(async (TrailersListResponse: Array<equipment>) => {
            if (TrailersListResponse)
                this.trailerListDetails = TrailersListResponse;
        });
        
        this.subscriptions$.sink = this.statusStore.pipe(select(fromloadStatusActions.getCurrentLoadStatus)).subscribe(loadStatus => {
            if (loadStatus) {
                this.scheduledArrival = loadStatus.scheduledArrival;
            }
        });  

        if(this.isCurrentJobLoading === false){
            this.getCurrentJob.emit();
          } 

        this.subscriptions$.sink = this.requestService.linksGet().subscribe(async (response) => {
            if (response.paperworkUploadProcess) {
                this.paperworkSubmissionVideoUrl = response.paperworkUploadProcess.url;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['trip']?.currentValue !== null && changes['trip']?.currentValue !== undefined) {
          this.loadCurrentTrip(this.trip);
        }
        if (changes['closeBottomDrawer']?.currentValue) {
            this.showBottomDrawer = false;
        }
      } 

    async loadCurrentTrip(currentTrip){
        this.driverId = await this.authService.getUserIdentitityKey();
        this.employeeNumber = await this.dispatchService.getEmployeeNumber();
        this.currentTrip = currentTrip;
        this.convertedTrip = this.currentTrip as JobTrip;
        Preferences.remove({ key: this.LOCAL_CURRENT_TRIP_KEY });
        Preferences.remove({ key: this.LOCAL_CURRENT_TRIP_DRIVER_KEY });
        Preferences.set({ key: this.LOCAL_CURRENT_TRIP_KEY, value: JSON.stringify(currentTrip) });
        Preferences.set({ key: this.LOCAL_CURRENT_TRIP_DRIVER_KEY, value: this.driverId });
        if (this.trailerReservation) {
            this.currentTrip.trailerReservationNotice = this.trailerReservation;
        }
        this.populateStatusSummary(this.currentTrip);
        this.showDriverCurrentTrip(this.currentTrip);
        this.createTrailerNumberList(this.currentTrip);
        this.populateSiteSafteyUrl(this.currentTripSteps);
        this.origin.city = this.currentTrip?.departureLocation.city;
        this.origin.state = this.currentTrip?.departureLocation.state;
        this.destination.city = this.currentTrip?.arrivalLocation.city;
        this.destination.state = this.currentTrip?.arrivalLocation.state;
        
        this.subscriptions$.sink = this.store.pipe(select(fromCurrentTrip.getTrailerReservation),
        takeWhile(() => this.componentActive)).subscribe((trailerReservation: trailerReservation) => {
            this.loadingUtility.hideLoader();
            if (trailerReservation) {
                this.trailerReservation = trailerReservation;
                if (this.currentTrip) {
                    this.currentTrip.trailerReservationNotice = this.trailerReservation;
                    this.populateStatusSummary(this.currentTrip);
                    this.showDriverCurrentTrip(this.currentTrip);
                }
            } else {
                this.store.dispatch(new tripActions.GetTrailerReservation(this.employeeNumber));
            }
        });      
        
        this.profile = await this.profileInfoService.getUserProfileInfo();
    }

    setDateLabel() {
        let isArrivalDateValid: boolean;
        let isDepartureDateValid: boolean;
        let isTargetDateValid: boolean;
    }

    // Handler called when the user taps on x or another button to close the options modal pop up
    stopOptionsModalCloseButtonClickHandler() {
        // this.showStopOptionsModal = false;
    }

    closeStartPaperworkModal() {
        this.showStartPaperworkModal = false;
    }

    getOriginDestination(trip: JobTrip, type: string) {
        return {
            "city": trip[type] ? trip[type].city : "",
            "state": trip[type] ? trip[type].stateCode : "",
        };
    }


    // Renders stop options pop
    async showStopOptions(event: any, step: TripStep, currentTrip: Trip) {
        if (!(event && event.target && event.target.innerText && event.target.innerText.toUpperCase() === 'START YOUR PAPERWORK')) {

            await Preferences.remove({ key: this.SELECTED_STOP_KEY });
            await Preferences.set({ key: this.SELECTED_STOP_KEY, value: JSON.stringify(step) });

            await Preferences.remove({ key: this.CLICKED_TRIP_KEY });
            await Preferences.set({ key: this.CLICKED_TRIP_KEY, value: JSON.stringify(currentTrip) });
        }
    }

    async loadCurrentTripFromLocalStorage() {
        const localDriverId = await Preferences.get({key: this.LOCAL_CURRENT_TRIP_DRIVER_KEY});
        const cachedDriverId = localDriverId.value;

        // We only pull from the cached data if it belongs to the current driver. This is an edge case but need to
        // do it for the sue case when we have user with two or more logins
        if (cachedDriverId !== null && cachedDriverId !== undefined && cachedDriverId.length > 0 &&
            cachedDriverId === this.driverId) {

            const localCurrentTrip = await Preferences.get({key: this.LOCAL_CURRENT_TRIP_KEY});
            const cachedCurrentTrip = JSON.parse(localCurrentTrip.value);

            if (cachedCurrentTrip) {
                const preAssignedTrip = cachedCurrentTrip;
                this.currentTrip = preAssignedTrip;
                if (preAssignedTrip.trailerReservationNotice !== null) {
                    this.statusSummary = preAssignedTrip.trailerReservationNotice;
                }
                this.populateStatusSummary(this.currentTrip);
                this.showDriverCurrentTrip(preAssignedTrip);
                this.origin.city = this.currentTrip.departureLocation.city;
                this.origin.state = this.currentTrip.departureLocation.state;
                this.destination.city = this.currentTrip.arrivalLocation.city;
                this.destination.state = this.currentTrip.arrivalLocation.state;
            }
        }
    }

    // Handler called when the component is discarded from memory
    ngOnDestroy(): void {
        this.componentActive = false;
        this.subscriptions$.unsubscribe();
    }

    // Sets the properties of the component in order to render information on the current trip
    showDriverCurrentTrip(driverTrip: trip) {
        if (driverTrip != null) {
            if (this.statusSummary) {
                this.statusDefinition = this.statusSummary.status;
            }

            this.currentTripLoadNotes = driverTrip.criticalLoadNotes;
            this.currentTripSteps = driverTrip.steps;
            if (driverTrip.trailerReservationNotice != null) {
                this.statusSummary = driverTrip.trailerReservationNotice;
                // this.statusSummary = null; // I (Christian) am leaving this here so that I can see the state of load status card where the is no trailer reservation notice
                this.statusDefinition = driverTrip.trailerReservationNotice.status;
                this.loadStatusCardTitle = 'Trailer Reservation Notice';
            } else {
                if (driverTrip.loadSummary != null) {
                    this.statusSummary = driverTrip.loadSummary;
                    this.statusDefinition = driverTrip.loadNumber;
                }

                this.loadStatusCardTitle = 'My Load Status Summary';
            }
        }
    }

    createTrailerNumberList(driverTrip: trip) {
        if (driverTrip != null) {
            if (driverTrip.steps && driverTrip.steps.length > 0) {
                let trailerNumberList = [];
                driverTrip.steps.forEach(element => {
                    if (element.trailerNumber && !trailerNumberList.includes(element.trailerNumber)) {
                        trailerNumberList.push(element.trailerNumber);
                    }
                });
                if (trailerNumberList && trailerNumberList.length > 0) {
                    let isExistRecords = false;
                    if (this.trailerListDetails && this.trailerListDetails.length > 0) {
                        isExistRecords = trailerNumberList.some(trailerNumber => {
                            return this.trailerListDetails.find(el => el.number === trailerNumber) ? true : false;
                        });
                    }
                    if (!isExistRecords) {
                        this.store.dispatch(new tripActions.GetTrailers(trailerNumberList));
                    }
                }
            }
        }
    }
    // Checks if the trip step card is the last one
    isBeforeLastTripStepCard(tripStepIndex: number): boolean {
        let isLast = false;

        // We only show a connector line , if the step is not the last one
        if (tripStepIndex === this.currentTripSteps.length - 2) {
            isLast = true;
        }

        return isLast;
    }

    // This function determines if we should show a connector line from a step to another
    connectorLineIsVisible(tripStepIndex: number): boolean {
        let isVisible = true;

        // We only show a connector line , if the step is not the last one
        if (tripStepIndex === this.currentTripSteps.length - 1) {
            isVisible = false;
        }

        return isVisible;
    }

    async callFleetManager() {
        await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
        const phoneNumberLink = 'tel:' + this.statusSummary.fleetManagerPhoneNumber;
        window.open(phoneNumberLink, '_system');
    }

    showPaperworkDrawer() {
        this.showBottomDrawer = !this.showBottomDrawer;
        if (this.showBottomDrawer) {

            this.analytics.trackEvent('User', 'Clicked Start Paperwork Button On Current Job Screen',
                'Clicked-Start-Paperwork-Button-On-Current-Job-Screen');

            this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_START_PAPERWORK_CURRENT_JOB);
            this.paperWorkStore.dispatch(
                new paperWorkActions.SelectedJob(this.convertedTrip));
        } else {
            // this is the case when the user taps on cancel button and the drawer is hidden
            this.analytics.trackEvent('User',
                'Clicked Cancel Button After Starting Paper Work On Current Job Screen',
                'Clicked-Cancel-Button-After-Starting-Paper-Work-On-Current-Job-Screen');

            this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_JOB_GENERATE_CANCEL);
        }
    }

    // Handler called when the user clicks on the View On Map Button
    async viewCurrentJobOnMap(tripStep: WernerDwpContractsLogicJobStep) {
        await this.setSelectedTripValue(tripStep);
        await Preferences.set({key: 'trip-step-on-map', value: JSON.stringify(tripStep)});
        await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
        this.router.navigate(['/loads/view-trailer']);
    }

    async generateJobEnvelopeAndScan() {
        this.bottomDrawer.closeDrawer();
        this.analytics.trackEvent('User', 'Clicked Generate Job Envelope And Scan Button On Current Job Screen',
            'Clicked-Generate-Job-Envelope-And-Scan-Button-On-Current-Job-Screen');

        this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_GENERATE_JOB);
        await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
        this.router.navigate(['/loads/paperwork']);

    }

    async scanPaperWork() {
        this.bottomDrawer.closeDrawer();
        this.analytics.trackEvent('User', 'Clicked Scan Paperwork Only Button On Current Job Screen',
            'Clicked-Scan-Paperwork-Only-Button-On-Current-Job-Screen');

        this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_SCAN_PAPERWORK);

        const recipient = this.currentTrip && this.currentTrip.clientContactName === 'STREAMLINE' ? environment.streamLineRecipient : environment.defaultRecipient;
        const data = await nativePlugin.scanPaperWork({
            recipient,
            identityId: this.driverId,
            appName: environment.appName,
            firstName: this.profile.user.firstName,
            lastName: this.profile.user.lastName,
            docuPath: '',
            isEdgeConnectDevice: this.isEdgeConnectDevice
        });
        if (data && data.confirmationNumber) {
            const confirmationCodes: ScannedPaperwork = {
                scannedBy: this.driverId,
                scanDateTime: new Date(Date.now()),
                numberOfPages: data.numberOfImages,
                originCityName:'',
                originStateName:'',
                destinationCityName:'',
                destinationStateCode:'',                
                tripId: '',
                label: {
                    labelName:"",
                    code: data.confirmationNumber
                  }
            };
            this.paperWorkStore.dispatch(
            new paperWorkActions.ConfirmationCodesToBePosted(confirmationCodes));
            await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
            this.router.navigate(['/loads/paperwork/confirmation']);
        }
    }

    populateStatusSummary(trip: trip) {
        if (trip && trip.trailerReservationNotice) {
            this.origin.isTrailerReservation = true;
            this.statusSummary = this.currentTrip.trailerReservationNotice;
        } else {
            this.statusSummary.tripId = (trip && trip.id) ? trip.id : "";
            if (trip) {
                const step = trip.steps.find(_ => _.type === JobTripStepType.SHIPPER);
                if (step) {
                    this.statusSummary.trailerNumber = step.trailerNumber;
                }
                this.statusSummary.isTrailerReservation = false;
                this.statusSummary.truckNumber = trip.tractorNumber;
            }
            if (this.profile && this.profile.fleetManagers) {
                this.statusSummary.fleetManagerPhoneNumber = this.profileUtility.getFleetManagerPhoneNumber(this.profile);
            }
        }
    }
    getSitesafetyalerturl(currentTripStep: WernerDwpContractsLogicJobStep) {
        this.subscriptions$.sink = this.requestService.sitesafetyalerturlGet(currentTripStep.location.id)
            .subscribe(async (response) => {
                this.loadingUtility.hideLoader();
                if (response !== null && response.length > 0 && response !== undefined) {
                    currentTripStep.siteSafetyAlertUrl = response;
                }
            },
                (error) => {
                    this.showToaster('Sorry, something went wrong:', 'Connection with the server is currently unavailable.', 'danger');
                    this.loadingUtility.hideLoader();
                });
    }
    populateSiteSafteyUrl(currentTripSteps: Array<WernerDwpContractsLogicJobStep>) {
        currentTripSteps.forEach(element => {
            if (element && !element.siteSafetyAlertUrl && element.location && element.location.id) {
                this.getSitesafetyalerturl(element);
            }
        });

    }
    async viewExistingPaperWork() {
        this.bottomDrawer.closeDrawer();
        this.analytics.trackEvent('User', 'Clicked View Existing Paper Work Button On Current Job Screen',
            'Clicked-View-Existing-Paper-Work-Button-On-Current-Job-Screen');
        await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
        this.telemetryUtility.recordTelemetry(TelemetryConstants.CLICK_EXISTING_PAPERWORK);
        this.router.navigate(['/loads/paperwork/confirmation_list']);
    }

    async startPreTripInspection() {
        await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
        this.router.navigate(['/loads/pre-trip-inspection']);
    }


    isStopType(tripStep: WernerDwpContractsLogicJobStep): boolean {
        return tripStep.type === JobTripStepType.STOP;
    }

    isArrivalType(tripStep: WernerDwpContractsLogicJobStep): boolean {
        return tripStep.type === JobTripStepType.ARRIVAL;
    }
    checkOriginDeparture(tripStep: WernerDwpContractsLogicJobStep): boolean {
        return this.isDepartureDate(tripStep);
    }

    isArrivalDate(tripStep: WernerDwpContractsLogicJobStep): boolean {
        if (tripStep.arrivalDate) {
            if (isNaN(new Date(tripStep.arrivalDate).getTime())) {
                return false;
            } else {
                return (new Date(tripStep.arrivalDate).getTime()) > 0;
            }
        } else {
            return false;
        }
    }

    isDepartureDate(tripStep): boolean {
        if (tripStep.departureDate) {
            if (isNaN(new Date(tripStep.departureDate).getTime())) {
                return false;
            } else {
                return (new Date(tripStep.departureDate).getTime()) > 0;
            }
        } else {
            return false;
        }
    }

    isTargetDate(tripStep): boolean {
        if (tripStep.targetDate) {
            if (isNaN(new Date(tripStep.targetDate).getTime())) {
                return false;
            } else {
                return (new Date(tripStep.targetDate).getTime()) > 0;
            }
        } else {
            return false;
        }
    }
    isShipperType(tripStep: WernerDwpContractsLogicJobStep) {
        return tripStep.type === JobTripStepType.SHIPPER;
    }
    getTimelineProgress(tripStep, stepIndex: number): any {
        const timeLineProgress = {
            'post-origin-timeline post-origin-timeline::after': this.isShipperType(tripStep),
            'post-origin-timeline-green post-origin-timeline-green::after': (this.isShipperType(tripStep) && this.isDepartureDate(tripStep)),

            'pre-stop-timeline pre-stop-timeline::after': this.isStopType(tripStep),
            'pre-stop-timeline-green pre-stop-timeline-green::after': this.isStopType(tripStep) && this.isArrivalDate(tripStep),

            'pre-destination-timeline  pre-destination-timeline::after': this.isArrivalType(tripStep),
            'pre-destination-timeline-green  pre-destination-timeline-green::after': this.isArrivalType(tripStep) && this.isArrivalDate(tripStep)
            // 'arrival-timeline arrival-timeline::after': this.checkStop(tripStep)
        };

        return timeLineProgress;
    }

    getStopProgress(tripStep: WernerDwpContractsLogicJobStep, stepIndex: number) {
        return {
            'top-container top-container::after': this.isShipperType(tripStep),
            'stop-container stop-container::after': this.isStopType(tripStep),
            'arrival-container arrival-container::after': this.isArrivalType(tripStep),
            'top-container-green top-container-green::after': this.isShipperType(tripStep) && this.isArrivalDate(tripStep),
            'stop-container-green stop-container-green::after': this.isStopType(tripStep) && this.isArrivalDate(tripStep),
            'arrival-container-green arrival-container-green::after': this.isArrivalType(tripStep) && this.isArrivalDate(tripStep),
        };
    }

    getPostStopTimeline(tripStep: WernerDwpContractsLogicJobStep, stepIndex: number): any {
        return {
            'post-stop-timeline': this.isStopType(tripStep),
            'post-stop-timeline-green': this.isStopType(tripStep) && this.isDepartureDate(tripStep),
        };
    }

    getCardCollapse(i: number) {

    }

    checkCardCollapse(i: number) {

    }
   
    async setSelectedTripValue(tripStep: WernerDwpContractsLogicJobStep) {
        await Preferences.remove({key: this.SELECTED_STOP_KEY});
        await Preferences.set({key: this.SELECTED_STOP_KEY, value: JSON.stringify(tripStep)});

        await Preferences.remove({key: this.CLICKED_TRIP_KEY});
        await Preferences.set({key: this.CLICKED_TRIP_KEY, value: JSON.stringify(this.currentTrip)});
    }

       

    viewSafetyAlert(tripStep: WernerDwpContractsLogicJobStep) {
        this.inAppBrowser.create(tripStep.siteSafetyAlertUrl);
    }

    async locationFeedback(tripStep: WernerDwpContractsLogicJobStep) {
        await Preferences.set({key: 'job-page-url', value: JSON.stringify({url:'/loads', tab:'Current'})});
        await this.setSelectedTripValue(tripStep);
        this.router.navigate(['/location-feedback']);
    }

    checkSafetyAlert(): boolean {
        return this.currentTrip.steps.some(_ => _.siteSafetyAlertUrl && _.siteSafetyAlertUrl.length > 0);
    }

    hasSiteSafetyAlert(sitesafteyUrl: string): boolean {
        return sitesafteyUrl ? true : false;
    }

    async showToaster(header, msg, type) {
        const toast = await this.toastController.create({
            header: header,
            message: msg,
            color: type,
            duration: 5000,
        });
        return toast.present();
    }

    async onClickUploadProcess() {
        if(this.paperworkSubmissionVideoUrl) {
            this.inAppBrowser.create(this.paperworkSubmissionVideoUrl, "_blank");
        }
    }
}
