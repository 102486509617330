import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from 'src/app/Swagger-Gen-V2';
import * as fromPaperWorkReducer from '../../paperwork/state/paperwork.reducer';
import * as paperWorkActions from '../../paperwork/state/paperwork.actions';
import { Store } from '@ngrx/store';
import { LoadingUtility } from 'src/app/shared/utilities/LoadingUtility';

@Injectable({
  providedIn: "root",
})
export class LoadService {
  closeBottomDrawer = new BehaviorSubject<boolean>(false);

  constructor(
    private paperWorkStore: Store<fromPaperWorkReducer.State>,
    private requestService: RequestService,
    private loadingUtility: LoadingUtility
  ) {}

  async fetchCurrentTrip(employeeNumber: string ): Promise<any | null> {  
    try {
      this.loadingUtility.showLoader("Please wait...");
      const currentTrip =  await this.requestService.tripsCurrentGet(parseInt(employeeNumber)).toPromise();
      this.waitAndHideLoader();
      return currentTrip; // Return the current trip
    } catch (error) {
      this.waitAndHideLoader();
      return null; // Return null in case of an error
    }
  }

  async fetchPreviousTrip(employeeNumber: string, driverId: string, ): Promise<any | null> {  
    try {
      this.loadingUtility.showLoader("Please wait...");
      const previousTrips =  await this.requestService.tripsGet('PAST', parseInt(employeeNumber), driverId).toPromise();
      this.waitAndHideLoader();
      return previousTrips; // Return the previous trip
    } catch (error) {
      this.waitAndHideLoader();
      return null; // Return null in case of an error
    }
  }

  async setPreviousTripInJonEnvelope(employeeNumber: string, driverId: string, tripId: string): Promise<void> {
    try {
      let previousTrips = await this.fetchPreviousTrip(employeeNumber, driverId);
  
      if (!previousTrips) return;

      const previousTrip = previousTrips.find(trip => trip.id === tripId);
  
      if (previousTrip) {
        this.paperWorkStore.dispatch(
          new paperWorkActions.SelectedJob(previousTrip)
        );
      }
    } catch (error) {
      console.error('Error setting previous trip in job envelope:', error);
      // Handle the error appropriately if needed
    }
  }
  
  
  async setCurrentTripInJonEnvelope(employeeNumber: string): Promise<void> {
    try {
      const currentTrip = await this.fetchCurrentTrip(employeeNumber);
  
      if (currentTrip) {
        this.paperWorkStore.dispatch(
          new paperWorkActions.SelectedJob(currentTrip)
        );
      }
    } catch (error) {
      console.error('Error setting current trip in job envelope:', error);
      // Handle the error appropriately if needed
    }
  }
  
  waitAndHideLoader() {
    setTimeout(() => {
      this.loadingUtility.hideLoader();
    },3000);
  }
  
}
